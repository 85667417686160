//runtime colors can be themes based on hostname
import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: 16,
    h1: {
      fontFamily: "Bungee, Roboto, Helvetica, Arial, sans-serif",
    }, h2: {
      fontFamily: "Bungee, Roboto, Helvetica, Arial, sans-serif",
    }, h3: {
      fontFamily: "Bungee, Roboto, Helvetica, Arial, sans-serif",
    }, h4: {
      fontFamily: "Bungee, Roboto, Helvetica, Arial, sans-serif",
    }, h5: {
      fontFamily: "Bungee, Roboto, Helvetica, Arial, sans-serif",
    }, h6: {
      fontFamily: "Bungee, Roboto, Helvetica, Arial, sans-serif",
    },
  },
  palette: {
    primary: {
      main: "#1E609E"
    },
    secondary: {
      main: "rgb(211, 54, 140)"
    },
    success: {
      main: "rgb(145, 162, 39)"
    },
  }
});

export default theme;
