import React, {createContext, Dispatch, ReactElement, SetStateAction, useEffect, useState} from 'react';
import {IQuestionnaireAnswers} from "../model/quiz/IQuestionnaireAnswers";
import {getDatabase, onValue, ref, set} from "firebase/database"
import {getAuth, signInAnonymously} from "firebase/auth"
import {DatabaseReference} from "@firebase/database";
import {IQuestionnaireAnalytics} from "../model/quiz/IQuestionnaireAnalytics";

interface Props {
    givenAnswers?: IQuestionnaireAnswers;
    setGivenAnswers?: Dispatch<SetStateAction<IQuestionnaireAnswers | undefined>>;
    userId?: string;
    setUserId?: Dispatch<SetStateAction<string>>;
    analytics?: IQuestionnaireAnalytics;
    setAnalytics?: Dispatch<SetStateAction<IQuestionnaireAnalytics | undefined>>;
}

interface ProviderProps {
    children: ReactElement;
}
export const StoreContext = createContext<Props>({})

const StoreProvider: React.FC<ProviderProps> = ({children}) => {
    const [givenAnswers, setGivenAnswers] = useState<IQuestionnaireAnswers>();
    const [userId, setUserId] = useState("");
    const [userDatabase, setUserDatabase] = useState<DatabaseReference>()
    const [analyticsDatabase, setAnalyticsDatabase] = useState<DatabaseReference>()
    const [analytics, setAnalytics] = useState<IQuestionnaireAnalytics>();
    const [loggedIntoFirebase, setLoggedIntoFirebase] = useState(false);

    //log the user in anonymously
    useEffect(() => {
        if(loggedIntoFirebase || window.location.pathname === "/health") return;

        const auth = getAuth();
        signInAnonymously(auth)
            .then((credentials) => {
                if(credentials.user) {
                    setUserId(credentials.user.uid);
                    setUserDatabase(ref(getDatabase(), `users/${credentials.user.uid}`));
                    setAnalyticsDatabase(ref(getDatabase(), `analytics/${credentials.user.uid}`));
                } else {
                    console.warn("no user uid found!");
                }
                setLoggedIntoFirebase(true);
            });
    }, [])

    //fetch given answers
    useEffect(() => {

        if(!userDatabase || !analyticsDatabase) return;

        onValue(userDatabase, (answers) => {

            if (answers.exists()) {

                const givenAnswers = answers.val() as IQuestionnaireAnswers;

                if(!givenAnswers.answers) givenAnswers.answers = []; //will be undefined when never set in firebase, for the code it is required

                // Firebase changes the 3d array structure to objects in certain circumstances - convert back to array
                // https://firebase.blog/posts/2014/04/best-practices-arrays-in-firebase
                for (let i = 0; i < givenAnswers.answers.length; i++) {
                    let answer = givenAnswers.answers[i];
                    if(answer && !Array.isArray(answer)) {
                        const array: any[] = [];
                        Object.keys(answer).forEach((key) => {
                            array[+key] = answer[+key];
                        });
                        givenAnswers.answers[i] = array;
                    }
                }

                setGivenAnswers(givenAnswers)
            } else {
                sendModelToFirebase();
            }

        });

        onValue(analyticsDatabase, (analyticsDataSnapshot) => {

            if (analyticsDataSnapshot.exists()) {

                const analytics = analyticsDataSnapshot.val() as IQuestionnaireAnalytics;

                setAnalytics(analytics || {})
            } else {
                sendModelToFirebase();
            }

        });
    }, [userDatabase]);


    useEffect(() => {
        sendModelToFirebase();
    }, [givenAnswers])

    useEffect(() => {
        sendAnalyticsToFirebase();
    }, [analytics])

    const sendModelToFirebase = () => {

        if (userDatabase && givenAnswers && givenAnswers.answers) {
            set(userDatabase, givenAnswers)
                .catch((err) => {
                    console.error(err);
            })
        }
    }

    const sendAnalyticsToFirebase = () => {
        if (analyticsDatabase && analytics) {
            set(analyticsDatabase, analytics)
                .catch((err) => {
                    console.error(err);
            })
        }
    }

    return <StoreContext.Provider value={{givenAnswers, setGivenAnswers, userId, setUserId, analytics, setAnalytics}}>{children}</StoreContext.Provider>
}

export default StoreProvider;
