import React, {useEffect} from 'react';
import './App.css';
import Header from "./component/Header";
import {Container, CssBaseline} from "@mui/material";
import AppRouter from "./AppRouter";
import {faviconReference, isDefaultTheme} from "./util/customThemingUtil";

function App() {

  useEffect(() => {
    if(!isDefaultTheme) {
      const faviconElement = document.getElementById("favicon");
      if(faviconElement instanceof HTMLLinkElement) {
        faviconElement.href = faviconReference;
      }
    }
  }, [isDefaultTheme])

  return (
      <>
        <Header/>
        <Container id="primaryContent">
          <CssBaseline>
            <AppRouter/>
          </CssBaseline>
        </Container>
      </>
  );
}

export default App;
