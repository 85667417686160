import React from 'react';
import { createRoot } from "react-dom/client";
import './index.css';
import reportWebVitals from './reportWebVitals';
import StoreProvider from "./context/StoreProvider";
import {ThemeProvider} from "@mui/material";
import {initializeApp} from "firebase/app";
import App from "./App";
import {theme} from "./util/customThemingUtil";

initializeApp(// For Firebase JS SDK v7.20.0 and later, measurementId is optional
    {
        apiKey: "AIzaSyBVHGU4S7o4KXmAotmu_xkvdNU75vPTVvA",
        authDomain: "verslavingskunde-c9b3e.firebaseapp.com",
        databaseURL: "https://verslavingskunde-c9b3e-default-rtdb.europe-west1.firebasedatabase.app/",
        // databaseURL: "http://localhost:9000/?ns=verslavingskunde-c9b3e-default-rtdb",
        projectId: "verslavingskunde-c9b3e",
        storageBucket: "verslavingskunde",
        messagingSenderId: "796964500019",
        appId: "1:796964500019:web:ad2c1b924fd3e9f96ee946",
        measurementId: "G-88WRSC6WXT"
    }
)

const container = document.getElementById('root');

if(!container) throw Error("Container not found");

const root = createRoot(container);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <StoreProvider>
                <App />
            </StoreProvider>
        </ThemeProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
