//runtime colors can be themes based on hostname
import { createTheme } from "@mui/material";

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!!!! IMPORTANT! This theme is used for both terwille.nl and ask-terwille.nl.    !!!!!
// !!!!! DO NOT make brand-specific changes here withouth splitting into two themes !!!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
const theme = createTheme({
  typography: {
    fontFamily: '"Open Sans", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontSize: 13,
    h1: {
      fontFamily: '"Open Sans", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif',
    }, h2: {
      fontFamily: '"Open Sans", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif',
    }, h3: {
      fontFamily: '"Open Sans", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif',
    }, h4: {
      fontFamily: '"Open Sans", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif',
    }, h5: {
      fontFamily: '"Open Sans", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif',
    }, h6: {
      fontFamily: '"Open Sans", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: '100px',
      color: "#5E5E5E",
      backgroundColor: "#ffffff"
    },
  },
  palette: {
    primary: {
      main: "#0069b4"
    },
    secondary: {
      main: "#56af31",
      contrastText: "#fff"
    },
    success: {
      main: "#034725"
    },
  },
  header: {
    logo: {
      width: '150px',
    }
  },
});

export default theme;
