import React from 'react';

import {BrowserRouter, Route, Routes} from "react-router-dom";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import {Stack} from "@mui/material";

const Questionnaire = React.lazy(() => import('./component/Questionnaire') )
const QuestionnaireFeedback = React.lazy(() => import('./component/QuestionnaireFeedback') )

const AppRouter = () => {

    return (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={
              <React.Suspense fallback={<span>Loading...</span>}>
                <Questionnaire />
              </React.Suspense>
            } />
            <Route path="/feedback" element={
              <React.Suspense fallback={<span>Loading...</span>}>
                <QuestionnaireFeedback/>
              </React.Suspense>
            } />
            <Route path="/health" element={<Stack mt={5} alignItems="center"><ThumbUpAltIcon /></Stack> } />
          </Routes>
        </BrowserRouter>
    );
};

export default AppRouter;
