//runtime colors can be themes based on hostname
import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: "Palanquin Dark, Roboto, Helvetica, Arial, sans-serif",
    fontSize: 16,
    h1: {
      fontFamily: "Palanquin Dark, Bungee, Roboto, Helvetica, Arial, sans-serif",
    }, h2: {
      fontFamily: "Palanquin Dark, Bungee, Roboto, Helvetica, Arial, sans-serif",
    }, h3: {
      fontFamily: "Palanquin Dark, Bungee, Roboto, Helvetica, Arial, sans-serif",
    }, h4: {
      fontFamily: "Palanquin Dark, Bungee, Roboto, Helvetica, Arial, sans-serif",
    }, h5: {
      fontFamily: "Palanquin Dark, Bungee, Roboto, Helvetica, Arial, sans-serif",
    }, h6: {
      fontFamily: "Palanquin Dark, Bungee, Roboto, Helvetica, Arial, sans-serif",
    },
  },
  palette: {
    primary: {
      main: "rgb(212, 14, 20)"
    },
    secondary: {
      main: "rgb(98, 184, 232)",
      contrastText: "rgb(255, 255, 255)"
    },
    success: {
      main: "rgb(145, 162, 39)"
    },
  },
  header: {
    transparant: true
  },
});

export default theme;
