//runtime colors can be themes based on hostname
import {createTheme} from "@mui/material";

// @ts-ignore
const theme = createTheme({
  typography: {
    fontSize: 16,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 25,
        },
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 64,
      color: "#5E5E5E",
      backgroundColor: "#ffffff"
    },
  },
  palette: {
    primary: {
      main: "#18BAAB",
      contrastText: "#ffffff"
    },
    secondary: {
      main: "#EF8200",
      contrastText: "rgb(255, 255, 255)"
    }
  }
});

export default theme;
