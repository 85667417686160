import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { logoReference } from "../util/customThemingUtil";
import ReadSpeakerButton from "./ReadSpeakerButton";
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';


const Header = () => {
  const theme = useTheme();
  const headerLogoWidth = theme.header?.logo?.width || '70px';
  const logoIsAbsolute = theme.header?.logo?.isAbsolute
  const transparent = theme.header?.transparant

  return (
    <Box sx={{ flexGrow: 1 }} className="Header">
      <AppBar position="static" color={transparent ? "transparent" : "primary"}>
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <img width={headerLogoWidth} src={logoReference} alt="Logo" style={logoIsAbsolute ? { "position": "absolute", top: 0 } : {}} />
            <Typography variant="h6" color={transparent ? "primary" : "inherit"} component="div" sx={{ flexGrow: 1, ml: logoIsAbsolute ? '103px' : 3 }}>
              Zelftest alcohol
            </Typography>
            <ReadSpeakerButton />
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
export default Header;
