//runtime colors can be themes based on hostname
import {createTheme} from "@mui/material";

// @ts-ignore
const theme = createTheme({
  typography: {
    fontSize: 17,
  },
  mixins: {
    toolbar: {
      minHeight: 64,
      backgroundColor: "#fdedef",
      color: "#E70000"
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          backgroundColor: "#FAD0D5",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: "#FAD0D5 !important",
          fontWeight: 500,
          lineHeight: "25px"
        }
      }
    }
  },
  palette: {
    background: {
      default: "#fdedef",
      // paper: "#fad0d5"
    },
    text: {
      primary: "#0036A0"
    },
    primary: {
      main: "#0036A0"
    },
    secondary: {
      main: "#29DD78",
      contrastText: "#ffffff"
    },
    error: {
      main: "#E70000"
    },
    success: {
      main: "#29DD78"
    },
  }
});

export default theme;
