//runtime colors can be themes based on hostname
import { createTheme } from "@mui/material";

// @ts-ignore
const theme = createTheme({
  typography: {
    fontFamily: "Bitter,serif",
    fontSize: 16,
    h1: {
      fontFamily: "Montserrat,sans-serif",
      color: "#000000 !important",
    }, h2: {
      fontFamily: "Montserrat,sans-serif",
      color: "#000000 !important",
    }, h3: {
      fontFamily: "Montserrat,sans-serif",
      color: "#000000 !important",
    }, h4: {
      fontFamily: "Montserrat,sans-serif",
      color: "#000000 !important",
    }, h5: {
      fontFamily: "Montserrat,sans-serif",
      color: "#000000 !important",
    }, h6: {
      fontFamily: "Montserrat,sans-serif",
      color: "#000000 !important",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          border: 0,
          borderRadius: 0,
          padding: '12px 22px',
          fontFamily: 'Montserrat,sans-serif',
          textTransform: 'none',
          backgroundColor: '#fff',
          color: '#333',
          boxShadow: 'none',
          position: 'relative', // Ensure this is here so the absolute positioning of :before is relative to the button
          overflow: 'hidden',
          zIndex: 2,
          '&:before': {
            zIndex: 1,
            content: '""',
            backgroundColor: '#ecbf00',
            display: 'block',
            width: '4px',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            transition: 'width .35s ease',
          },
          '&:hover': {
            border: 0,
            color: '#fff',
            boxShadow: 'none',
            backgroundColor: '#fff',
            '&:before': {
              width: '100%',
            },
            transition: 'color .3s ease',
            transitionProperty: 'color, background-color, border-color',
          },
          '& > *': { // Target all direct children of the button
            position: 'relative',
            zIndex: 2,
          },
        },
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          backgroundColor: "#CCE8E3"
        }
      }
    },
  },
  mixins: {
    toolbar: {
      minHeight: 64,
      color: "#5E5E5E",
      backgroundColor: "#ffffff"
    },
  },
  palette: {
    primary: {
      main: "#006271",
      contrastText: "#fff"
    },
    secondary: {
      main: "#CCE8E3",
      contrastText: "000000"
    }
  }
});

export default theme;
