//runtime colors can be themes based on hostname
import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto Slab, Roboto, sans-serif',
    fontSize: 13,

    h1: {
      color: "#30699e !important",
      fontSize: '25px',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 700,
    }, h2: {
      color: "#30699e !important",
      fontSize: '22px',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 700,
    }, h3: {
      color: "#30699e !important",
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 900,
    }, h4: {
      color: "#30699e !important",
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 900,
    }, h5: {
      color: "#30699e !important",
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 900,
    }, h6: {
      color: "#30699e !important",
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 900,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '50px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
        },
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: '70px',
      backgroundColor: "#ffffff"
    },
  },
  palette: {
    background: {
      default: "#f3f6f8",
    },
    text: {
      primary: "#404040",
      secondary: "#404040",
    },
    primary: {
      main: "#4c2679",
      contrastText: '#fff'
    },
    secondary: {
      main: "#9f6b23",
      contrastText: "#fff"
    },
    success: {
      main: "#034725"
    },
  },
  header: {
    logo: {
      width: '157px',
    }
  },
});

export default theme;
