//runtime colors can be themes based on hostname
import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: 'Lato, sans-serif',
    fontSize: 13,
    h1: {
      fontSize: '25px',
      fontFamily: 'Bebas Neue, sans-serif',
    }, h2: {
      fontSize: '22px',
      fontFamily: 'Bebas Neue, sans-serif',
    }, h3: {
      fontFamily: 'Bebas Neue, sans-serif',
    }, h4: {
      fontFamily: 'Bebas Neue, sans-serif',
    }, h5: {
      fontFamily: 'Bebas Neue, sans-serif',
    }, h6: {
      fontFamily: 'Bebas Neue, sans-serif',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
        },
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: '49px',
      color: "#2DAACD",
      backgroundColor: "#ECECEC"
    },
  },
  palette: {
    primary: {
      main: "#00AFCC",
      contrastText: '#fff'
    },
    secondary: {
      main: "#197496",
      contrastText: "#fff"
    },
    success: {
      main: "#034725"
    },
  },
  header: {
    logo: {
      isAbsolute: true,
    }
  }
});

export default theme;
