//runtime colors can be themes based on hostname
import { createTheme } from "@mui/material";

// @ts-ignore
const theme = createTheme({
  typography: {
    fontFamily: "Barlow Semi Condensed, Arial",
    fontSize: 16,
    h1: {
      fontFamily: "Cornerstone Regular, Barlow Semi Condensed, Arial",
    }, h2: {
      fontFamily: "Cornerstone Regular, Barlow Semi Condensed, Arial",
    }, h3: {
      fontFamily: "Cornerstone Regular, Barlow Semi Condensed, Arial",
    }, h4: {
      fontFamily: "Cornerstone Regular, Barlow Semi Condensed, Arial",
    }, h5: {
      fontFamily: "Cornerstone Regular, Barlow Semi Condensed, Arial",
    }, h6: {
      fontFamily: "Cornerstone Regular, Barlow Semi Condensed, Arial",
    },
  },
  mixins: {
    toolbar: {
      backgroundColor: "#fff",
      height: '60px',
      color: "#000"
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          color: "#fff",
          backgroundColor: "#000",
          "& .MuiAlert-icon": {
            color: '#fff',
            alignItems: 'center'
          }
        }
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        }
      }
    }
  },
  palette: {
    text: {
      primary: "#9b9da1"
    },
    primary: {
      main: "#000",
      contrastText: "#fff"
    },
    secondary: {
      main: "#000",
      contrastText: "#fff"
    },
  },
  header: {
    transparant: true,
  }
});

export default theme;
