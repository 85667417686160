//runtime colors can be themes based on hostname
import { createTheme } from "@mui/material";

// @ts-ignore
const theme = createTheme({
  typography: {
    fontFamily: "Arial",
    fontSize: 16,
    h1: {
      fontFamily: "Arial",
    }, h2: {
      fontFamily: "Arial",
    }, h3: {
      fontFamily: "Arial",
    }, h4: {
      fontFamily: "Arial",
    }, h5: {
      fontFamily: "Arial",
    }, h6: {
      fontFamily: "Arial",
    },
  },
  mixins: {
    toolbar: {
      backgroundColor: "#fff",
      color: "#000"
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          backgroundColor: "#F2F2F2"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        }
      }
    }
  },
  palette: {
    text: {
      primary: "#111111"
    },
    primary: {
      main: "#000",
      contrastText: "#fff"
    },
    secondary: {
      main: "#000",
      contrastText: "#fff"
    },
  },
});

export default theme;
