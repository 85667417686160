import React from "react";

const ReadSpeakerButton = () => {

  return (
    <div id="readspeaker_button1" className="rs_skip rsbtn rs_preserve" style={{ marginTop: "10px" }}>
        <a rel="nofollow" className="rsbtn_play" accessKey="L" title="Laat de tekst voorlezen met ReadSpeaker webReader"
           href={"https://app-eu.readspeaker.com/cgi-bin/rsent?customerid=12795&amp;lang=nl_nl&amp;readid=primaryContent&amp;url=" + encodeURIComponent(document.location.href)}>
          <span className="rsbtn_left rsimg rspart"><span className="rsbtn_text"><span>Lees voor</span></span></span>
          <span className="rsbtn_right rsimg rsplay rspart"></span>
        </a>
      </div>
  )
}

export default ReadSpeakerButton;
