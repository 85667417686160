//runtime colors can be themes based on hostname
import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: 16,
    h1: {
      fontSize: '25px',
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 600
    }, h2: {
      fontSize: '22px',
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 600
    }, h3: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 600
    }, h4: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 600
    }, h5: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 600
    }, h6: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 600
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '3rem',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: "#CE007C !important",
          color: '#fff !important',
          "& .MuiSvgIcon-root": {
            color: '#fff !important',
          },
          "& .MuiTypography-root": {
            color: '#fff !important',
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.feedback-card": {
            color: '#fff !important',
            background: "linear-gradient(0deg,#a92a4c 40%,#ce007c)",
            "& .MuiTypography-root": {
              color: '#fff !important',
            },
            "& .MuiButton-root": {
              border: "1px solid #fff !important",
            },
            "&:hover": {
              background: "#76bc26",
            }
          }
        }
      }
    }
  },
  mixins: {
    toolbar: {
      minHeight: '102px',
      color: "#CE007C",
      backgroundColor: "#fff"
    },
  },
  palette: {
    primary: {
      main: "#CE007C",
      contrastText: '#fff'
    },
    secondary: {
      main: "#76BC26",
      contrastText: "#fff"
    },
    success: {
      main: "#034725"
    },
  },
  feedback: {
    howToContinueCardClickable: true
  }
});

export default theme;
