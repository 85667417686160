//runtime colors can be themes based on hostname
import {createTheme} from "@mui/material";

// @ts-ignore
const theme = createTheme({
  typography: {
    fontFamily: "PT Sans, Roboto, Helvetica, Arial, sans-serif",
    fontSize: 16,
    h1: {
      fontFamily: "PT Sans, Bungee, Roboto, Helvetica, Arial, sans-serif",
    },h2: {
      fontFamily: "PT Sans, Bungee, Roboto, Helvetica, Arial, sans-serif",
    },h3: {
      fontFamily: "PT Sans, Bungee, Roboto, Helvetica, Arial, sans-serif",
    },h4: {
      fontFamily: "PT Sans, Bungee, Roboto, Helvetica, Arial, sans-serif",
    },h5: {
      fontFamily: "PT Sans, Bungee, Roboto, Helvetica, Arial, sans-serif",
    },h6: {
      fontFamily: "PT Sans, Bungee, Roboto, Helvetica, Arial, sans-serif",
    },
  },
  mixins: {
    toolbar: {
      minHeight: 64,
      backgroundColor: "#c67db4"
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          backgroundColor: "#F2F2F2"
        }
      }
    },
  },
  palette: {
    text: {
      primary: "#111111"
    },
    primary: {
      main: "#a5027d"
    },
    secondary: {
      main: "#a5027d",
      contrastText: "rgb(255, 255, 255)"
    },
    success: {
      main: "rgb(145, 162, 39)"
    },
  }
});

export default theme;
